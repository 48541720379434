<template>
  <div class="flex-1 flex flex-col overflow-hidden border bg-white border-gray-300 rounded">
    <div class="flex-1 overflow-y-scroll relative">
      <p-loader v-if="isLoading" />
      <table v-else class="data-table">
        <thead>
          <tr>
            <th v-html="$tk('Reports.CustomerId')"></th>
            <th v-html="$tk('Reports.CustomerName')"></th>
            <th v-html="$tk('Reports.LocationId')"></th>
            <th v-html="$tk('Reports.GLN')"></th>
            <th v-html="$tk('Reports.ExternalId')"></th>
            <th v-html="$tk('Reports.LocationName')"></th>
            <th v-html="$tk('Common.Address.Country')"></th>
            <th v-html="$tk('Reports.SuperUser')"></th>
            <th v-html="$tk('Common.General.Phone')"></th>
            <th v-html="$tk('Common.General.Email')"></th>
            <th v-if="isSmartRetur" v-html="$tk('Reports.Info')"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(location, index) in locations" :key="index">
            <td>{{location.customerId}}</td>
            <td>{{location.customerName}}</td>
            <td>{{location.id.split(':')[0]}}</td>
            <td>{{location.gln}}</td>
            <td>{{location.id.split(':')[1]}}</td>
            <td>{{location.name}}</td>
            <td>{{location.company}}</td>
            <td>{{location.superUser ? location.superUser.name : '-'}}</td>
            <td>{{location.superUser ? location.superUser.phone : '-'}}</td>
            <td>{{location.superUser ? location.superUser.email : '-'}}</td>
            <td v-if="isSmartRetur">{{location.info}}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <portal to="header">
      <div class="ml-1 text-xl font-medium text-gray-900">{{$tk('Reports.Locations', true)}}</div>
    </portal>

    <portal to="actions">
      <div class="ml-8 flex space-x-2">
        <PButton 
          color="secondary" 
          icon="file-excel" 
          @click="onDownload"
          :loading="isDownloading"
        >
          Excel
        </PButton>
      </div>
    </portal>

  </div>
</template>

<script>
  
  import http from "@/http"
  import { orderBy } from 'lodash'
  import { mapGetters } from 'vuex'
  import { format } from "date-fns"
  import fileDownload from 'js-file-download'

  export default {
    
    name: "Locations",
    
    data () {
      return {
        isLoading: true,
        isDownloading: false,
        locations: []
      }
    },
    
    computed: {
      ...mapGetters([
        'location',
        'loginUser'
      ]),
      isSmartRetur () {
        return this.loginUser.isSmartRetur
      }
    },

    methods: {

      async onDownload () {
        this.isDownloading = true

        try {

          const data = await http.get("AvailableLocations", { params: { excel: "download" }, responseType: "blob" })
          fileDownload(data, `${format(new Date(), "yyyy-MM-dd")}_locations.xlsx`)

        } catch {
          //
        }
        
        this.isDownloading = false
      }

    },

    async created () {
      /*
      const params = {
        poolingGroupId: 
      }*/
      // const response = await http.get("availableLocations?withIntercompany=true") 
      this.locations = await orderBy(await http.get("AvailableLocations"), ['customerName', 'locationName'], ['asc', 'asc'])
      this.isLoading = false
    }
  }
</script>

<style lang="scss" scoped>

</style>